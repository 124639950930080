import React, {useRef} from 'react'
import Link from 'next/link'
import {useTranslation} from 'next-i18next'

import useTokenLandingStore, {TokenLandingTypes} from '@feature/landing/pages/token/hooks/useTokenLandingStore'
import {useIsVisible} from '@hook/useIsVisible'
import useWindowSize from '@hook/useWindowSize'

import FadeInView from '@component/animation/fadeIn/FadeInView'
import Text from '@component/Text/Text'

import CadenzaIcon from '@svg/token/logo_backer_cadenza.svg'
import BitmexIcon from '@svg/token/logo_backer_bitmex.svg'
import MiranaIcon from '@svg/token/logo_backer_mirana.svg'
import C3Icon from '@svg/token/logo_backer_c3.svg'
import NeowizIcon from '@svg/token/logo_backer_neowiz.svg'
import AbcPartnersIcon from '@svg/token/logo_backer_abc_partners.svg'
import {createLog} from '@util/logs'

type TBacker = {
    id: string
    logo: React.ReactNode
    url: string
}

interface IProps {
    className?: string
}

const WEB_LOGO_WIDTH = 190
const WEB_LOGO_HEIGHT = 60
const MOBILE_LOGO_WIDTH = 79
const MOBILE_LOGO_HEIGHT = 25
const TokenBackersContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {isMd} = useWindowSize()

    const {pageType} = useTokenLandingStore()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)

    const backers: TBacker[] = [
        {
            id: 'cadenza',
            logo: (
                <CadenzaIcon
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://cadenza.vc/',
        },
        {
            id: 'bitmex',
            logo: (
                <BitmexIcon
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.bitmex.com/',
        },
        {
            id: 'mirana',
            logo: (
                <MiranaIcon
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.mirana.xyz/team',
        },
        {
            id: 'c3',
            logo: (
                <C3Icon
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.c3venturecapital.com/',
        },
        {
            id: 'neowiz',
            logo: (
                <NeowizIcon
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'https://www.neowiz.com/',
        },
        {
            id: 'abc_partners',
            logo: (
                <AbcPartnersIcon
                    width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH}
                    height={isMd ? MOBILE_LOGO_HEIGHT : WEB_LOGO_HEIGHT}
                />
            ),
            url: 'http://www.abc-partners.com/',
        },
    ]
    return (
        <section
            ref={fadeAnimationRef}
            className={`layout-full_desktop max-w-[1280px] py-[200px] land:py-[150px] md:py-[100px] sm:py-[70px] land:px-[40px] md:px-[20px] space-y-[150px] land:space-y-[100px] md:space-y-[50px]`}>
            <div className={'space-y-[40px] md:space-y-[20px]'}>
                <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN}>
                    <Text
                        className={
                            'text-white text-center text-landing_heading2 md:text-landing_body_large md:font-semibold !font-landing'
                        }>
                        {t('landingTokenSale.TokenInfo.backers.title')}
                    </Text>
                </FadeInView>
                <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-500'}>
                    <Text className={'text-white text-center typo-landing_body_medium md:typo-landing_body_caption'}>
                        {t('landingTokenSale.TokenInfo.backers.desc')}
                    </Text>
                </FadeInView>
            </div>
            <div className={'grid grid-cols-3 gap-[32px] md:gap-[10px]'}>
                {backers?.map(backer => (
                    <Link
                        onClick={() => {
                            createLog('event', 'token_landing_backers', {backer: backer?.id})
                        }}
                        href={backer?.url}
                        key={backer?.id}
                        className={
                            ' flex justify-center items-center bg-[#1F1F29] rounded-[10px] py-[70px] land:py-[40px] md:py-[20px]'
                        }
                        target={'_blank'}>
                        {backer?.logo}
                    </Link>
                ))}
            </div>
        </section>
    )
}
export default TokenBackersContainer
