import React from 'react'
import {IAnnouncement} from '@api/announce/getAnnouncements'
import WizImage from '@component/images/WizImage'
import Text from '@component/Text/Text'
import useFormatDate from '@hook/useFormatDate'
import {useTranslation} from 'next-i18next'
import useNavigation from '@hook/useNavigation'
import useWindowSize from '@hook/useWindowSize'
import AnnounceDefaultImage from '@image/announce/img_announce_default.webp'
import {createLog} from '@util/logs'

const TokenLandingAnnounceBannerCard: React.FC<IAnnouncement> = ({id, title, created_at, thumbnail, images}) => {
    const {isMd, isCustom} = useWindowSize(1325)
    const {t} = useTranslation()
    const {goAnnouncementDetail} = useNavigation()
    const {displayDate} = useFormatDate()

    return (
        <div
            className={
                'w-full h-full flex flex-col justify-between rounded-[5px] bg-[#FAFFC210] cursor-pointer pb-[20px] md:pb-[14.8px]'
            }
            onClick={() => {
                goAnnouncementDetail(id)
                createLog('event', 'token_landing_announce_click')
            }}>
            <div className={'space-y-[20px] md:space-y-[14.8px]'}>
                <div className={' md:h-[140px] rounded-t-[5px] overflow-hidden'}>
                    <WizImage
                        src={thumbnail || AnnounceDefaultImage}
                        width={isMd ? 260 : isCustom ? 193.5 : 276}
                        height={isMd ? 140 : 158}
                        isResize={false}
                        imageClassName={'object-fill'}
                    />
                </div>

                <Text
                    className={
                        'px-[20px] md:px-[14.8px] text-landing_body_small leading-[22px] font-bold text-white md:text-landing_body_caption md:font-bold line-clamp-2'
                    }>
                    {title}
                </Text>
            </div>

            <Text
                className={
                    'px-[20px] md:px-[14.8px] typo-landing_body_small leading-[22px] text-[#C9CAC3] md:typo-landing_body_caption'
                }>
                {displayDate(created_at, t('dateFormat.dateAndTime'))}
            </Text>
        </div>
    )
}
export default TokenLandingAnnounceBannerCard
