import React, {useRef} from 'react'
import {useTranslation} from 'next-i18next'
import FadeInView from '@component/animation/fadeIn/FadeInView'
import {useIsVisible} from '@hook/useIsVisible'
import Text from '@component/Text/Text'
import CopyTradingImage from '@image/token/image_token_landing_copy_trading_service.webp'
import useTokenLandingStore, {TokenLandingTypes} from '@feature/landing/pages/token/hooks/useTokenLandingStore'
import WizImage from '@component/images/WizImage'

interface IProps {
    className?: string
}

const TokenServiceCopyTradingContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()

    const {pageType} = useTokenLandingStore()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)
    return (
        <div className={`layout-full_desktop max-w-[1280px] ${className}`}>
            <div className={'py-[150px] md:py-[100px] land:px-[40px] md:px-[20px]'}>
                <div ref={fadeAnimationRef} className={' space-y-[40px]'}>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.SERVICE}>
                        <Text
                            className={
                                'text-white text-landing_heading2 md:text-landing_body_large text-center md:font-bold !font-landing'
                            }>
                            {t('landingTokenSale.TokenService.copy.trading.title')}
                        </Text>
                    </FadeInView>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.SERVICE} delay={'delay-500'}>
                        <Text
                            className={
                                'text-[#C9CAC3] typo-landing_body_medium text-center md:typo-landing_body_caption px-[200px] land:px-[90px] md:px-0'
                            }>
                            {t('landingTokenSale.TokenService.copy.trading.desc')}
                        </Text>
                    </FadeInView>
                </div>
                <div className={'flex flex-col items-center'}>
                    <WizImage src={CopyTradingImage} containerClassName={' max-w-[1280px]'} />
                </div>
            </div>
        </div>
    )
}
export default TokenServiceCopyTradingContainer
