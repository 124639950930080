import React, {useMemo, useRef, useState} from 'react'
import Text from '@component/Text/Text'
import WizAccordion from '@component/accordion/WizAccordion'
import TagText from '@component/Text/TagText'
import {useTranslation} from 'next-i18next'
import WizImage from '@component/images/WizImage'
import referralENImageSource from '@image/landing/img_welcome_referral_en.png'
import referralMobileENImageSource from '@image/landing/img_welcome_referral_mobile_en.png'
import referralKOImageSource from '@image/landing/img_welcome_referral_ko.png'
import referralMobileKOImageSource from '@image/landing/img_welcome_referral_mobile_ko.png'
import useWindowSize from '@hook/useWindowSize'
import {useRouter} from 'next/router'
import FadeInView from '@component/animation/fadeIn/FadeInView'
import {useIsVisible} from '@hook/useIsVisible'
import {createLog} from '@util/logs'

const renderDescListStyle = 'text-[#C9CAC3] typo-landing_body_medium sm:typo-landing_body_small'

type TWelcomeFaqAccordion = {
    title: string
    content: React.ReactNode
}

interface IProps {
    className?: string
}

const TokenLandingFAQContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const router = useRouter()
    const {isSm} = useWindowSize()

    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)

    const [selectedItem, setSelectedItem] = useState<number>()

    const referralImage = useMemo(() => {
        if (router?.locale === 'ko') {
            return isSm ? referralMobileKOImageSource : referralKOImageSource
        } else {
            return isSm ? referralMobileENImageSource : referralENImageSource
        }
    }, [router?.locale, isSm])

    const onClickToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        e.stopPropagation()
        createLog('event', 'token_landing_faq')
        if (selectedItem === index) {
            return setSelectedItem(undefined)
        }
        setSelectedItem(index)
    }

    const welcomefaqList: TWelcomeFaqAccordion[] = [
        {
            title: t('landingWelcome.faq.faq1.title'),
            content: (
                <ul>
                    <li className={renderDescListStyle}>
                        <TagText>{t('landingWelcome.faq.faq1.desc')}</TagText>
                    </li>
                </ul>
            ),
        },
        {
            title: t('landingTokenSale.faq.point.title'),
            content: (
                <ul>
                    <li className={renderDescListStyle}>
                        <TagText>{t('landingTokenSale.faq.point.desc')}</TagText>
                    </li>
                </ul>
            ),
        },
        {
            title: t('landingWelcome.faq.faq2.title'),
            content: (
                <ul>
                    <li className={renderDescListStyle}>
                        <TagText>{t('landingWelcome.faq.faq2.desc')}</TagText>
                    </li>
                </ul>
            ),
        },
        {
            title: t('landingWelcome.faq.faq3.title'),
            content: (
                <>
                    <ol className={'list-decimal pl-[15px]'}>
                        <li className={renderDescListStyle}>
                            <TagText>{t('landingWelcome.faq.faq3.desc1')}</TagText>
                        </li>
                        <li className={renderDescListStyle}>
                            <TagText>{t('landingWelcome.faq.faq3.desc2')}</TagText>
                        </li>
                        <li className={renderDescListStyle}>
                            <TagText>{t('landingWelcome.faq.faq3.desc3')}</TagText>
                        </li>
                        <li className={renderDescListStyle}>
                            <TagText>{t('landingWelcome.faq.faq3.desc4')}</TagText>
                        </li>
                        <li className={renderDescListStyle}>
                            <TagText>{t('landingWelcome.faq.faq3.desc5')}</TagText>
                        </li>
                    </ol>
                    <br />
                    <TagText className={renderDescListStyle}>{t('landingWelcome.faq.faq3.desc6')}</TagText>
                </>
            ),
        },
        {
            title: t('landingWelcome.faq.faq4.title'),
            content: (
                <ul>
                    <li className={renderDescListStyle}>
                        <TagText>{t('landingWelcome.faq.faq4.desc')}</TagText>
                    </li>
                </ul>
            ),
        },
        {
            title: t('landingWelcome.faq.faq5.title'),
            content: (
                <ul>
                    <li className={renderDescListStyle}>
                        <TagText className={'mb-[15px]'}>{t('landingWelcome.faq.faq5.desc')}</TagText>
                        <WizImage src={referralImage} />
                    </li>
                </ul>
            ),
        },
        {
            title: t('landingWelcome.faq.faq6.title'),
            content: (
                <ul>
                    <li className={renderDescListStyle}>
                        <TagText>{t('landingWelcome.faq.faq6.desc')}</TagText>
                    </li>
                </ul>
            ),
        },
    ]

    return (
        <section className={className}>
            <div className={'bg-[#1F1F26] px-[20px] land:px-[40px] md:px-[20px] py-[100px] md:py-[70px]'}>
                <div
                    ref={fadeAnimationRef}
                    className={'w-full max-w-[1280px] mx-auto  space-y-[60px] md:space-y-[20px]'}>
                    <FadeInView isVisible={isVisible}>
                        <Text className={'typo-landing_heading2 text-white md:typo-landing_display1'}>
                            {t('landingWelcome.faq.title')}
                        </Text>
                    </FadeInView>
                    <div className={'w-full'}>
                        {welcomefaqList.map((item, index) => {
                            return (
                                <WizAccordion
                                    key={item.title}
                                    open={selectedItem === index}
                                    className={`border-b border-[#FFFFFF30] rounded-none first:border-t`}
                                    iconClassName={'fill-dark_gray01'}
                                    titleContainerClassName={'px-[10px] p-[31px] md:py-[23.5px]'}
                                    titleClassName={`self-start typo-landing_body_large font-bold md:typo-landing_body_small md:font-bold md:subpixel-antialiased ${
                                        selectedItem === index
                                            ? `bg-gradient-token inline-block text-transparent bg-clip-text`
                                            : 'text-white'
                                    }`}
                                    contentClassName={`typo-landing_body_medium px-[40px] ${
                                        selectedItem === index ? 'pb-[30px] md:pb-[20px]' : 'pb-0 sm:pb-0'
                                    }`}
                                    renderRightIcon={
                                        <Text
                                            className={`typo-landing_body_large font-bold md:typo-landing_body_small md:font-bold md:subpixel-antialiased sm:self-start ${
                                                selectedItem === index
                                                    ? `bg-gradient-token inline-block text-transparent bg-clip-text`
                                                    : 'text-white'
                                            }`}>
                                            {t('landingWelcome.faq.title.q.mark')}
                                        </Text>
                                    }
                                    title={item.title}
                                    onToggle={e => onClickToggle(e, index)}>
                                    {item.content}
                                </WizAccordion>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenLandingFAQContainer
